* > .modal-dialog {
  @apply max-w-none;
}

.default-setting-size > .modal-dialog {
  @apply w-[70rem];
}

.open-sale-size > .modal-dialog {
  @apply w-[35.5rem];
}

.add-member-size > .modal-dialog {
  @apply max-w-none w-[76rem];
}

.delete-size > .modal-dialog {
  @apply md:w-[35rem] mr-0 ml-0 md:m-auto;
}

.new-project-size > .modal-dialog {
  @apply w-[40.438rem];
}
.layout-sample>.modal-dialog {
  @apply w-[41.5rem]
}
.choose-widget>.modal-dialog {
  @apply lg:w-[50%] md:w-[80%] xs:w-full md:my-0
}

.share-dialog-reponsive>.modal-dialog {
  @apply lg:w-[50%] md:w-[80%] md:my-0
}

.choose-sample-time-size > .modal-dialog{
  @apply w-[42.5rem]
}
.shared-dialog>.modal-dialog {
  @apply md:w-[42.5rem] flex sm:items-center items-end mb-0 mr-0 ml-0 md:m-auto;
}

.modal-dialog-centered{
  @apply flex sm:items-center items-end;
 }

