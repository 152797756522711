.svg-gray-500 {
  filter: brightness(0) saturate(100%) invert(49%) sepia(11%) saturate(703%) hue-rotate(183deg) brightness(86%)
    contrast(89%);
}

.svg-error-600 {
  filter: brightness(0) saturate(100%) invert(18%) sepia(69%) saturate(4021%) hue-rotate(353deg) brightness(99%)
    contrast(87%);
}

.svg-primary-400 {
  filter: brightness(0) saturate(100%) invert(69%) sepia(49%) saturate(3520%) hue-rotate(181deg) brightness(102%)
    contrast(98%);
}

.svg-primary-500 {
  filter: brightness(0) saturate(100%) invert(51%) sepia(57%) saturate(4203%) hue-rotate(194deg) brightness(102%)
    contrast(96%);
}

.svg-primary-600 {
  filter: brightness(0) saturate(100%) invert(36%) sepia(46%) saturate(6376%) hue-rotate(206deg) brightness(98%)
    contrast(92%);
}

.svg-blue-400 {
  filter: invert(69%) sepia(49%) saturate(3520%) hue-rotate(181deg) brightness(102%) contrast(98%);
}

.svg-blue-500 {
  filter: brightness(0) saturate(100%) invert(51%) sepia(57%) saturate(4203%) hue-rotate(194deg) brightness(102%)
    contrast(96%);
}

.svg-blue-600 {
  filter: brightness(0) saturate(100%) invert(36%) sepia(46%) saturate(6376%) hue-rotate(206deg) brightness(98%)
    contrast(92%);
}

.svg-orange-dark-50 {
  filter: brightness(0) saturate(100%) invert(82%) sepia(32%) saturate(191%) hue-rotate(319deg) brightness(111%)
    contrast(105%);
}

.svg-primary-50 {
  filter: brightness(0) saturate(100%) invert(81%) sepia(31%) saturate(191%) hue-rotate(192deg) brightness(112%)
    contrast(102%);
}

.svg-green-50 {
  filter: brightness(0) saturate(100%) invert(92%) sepia(85%) saturate(146%) hue-rotate(54deg) brightness(109%)
    contrast(98%);
}
