.input-basic {
  @apply rounded-lg w-full max-h-11;
  &:disabled {
    @apply bg-gray-400;
  }
}

.textarea-basic {
  @apply rounded-lg w-full max-h-24;
}

.p-input-basic > .p-calendar {
  @apply rounded-lg w-full max-h-24;
}
